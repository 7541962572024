@import url('../fonts/fonts.css');
body {
  margin: 0;
  font-family: 'Futura PT', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.app-wrapper {
  display: flex;
  margin: auto;
  justify-content: center;
}
.buy_nav {
  display: flex;
  height: 28px;
}
.buy_nav span {
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.35px;
  display: flex;
  flex: 1;
  justify-content: center;
}
.buy_nav span.active {
  color: #051B2E;
}
.buy_nav span:not(.active) {
  color: #DBE3EA;
}
.buy_nav span:last-child {
  justify-content: right;
}
.buy_nav span:first-child {
  justify-content: left;
}
.days_list {
    display: flex;
    margin-bottom: 32px;
}
.days_list-item {
    display: flex;
    width: 80px;
    height: 110px;
    flex-direction: column;
    border-radius: 16px;
    border: 2px solid #051B2E;
    padding: 8px;
    text-align: center;
    box-sizing: border-box;
}
.days_list-item.active, .days_list-item.active > button {
  background: #051B2E;
  color: #fff;
}
.days_list-item:not(.active), .days_list-item:not(.active) > button {
  background: #fff;
  color: #051B2E;
}
.days_list-item > button {
  border: 0;
  background: unset;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.days_list-item:not(.active) > button > span {
  margin-bottom: 10px;
}
.day-name {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.35px;
  margin-bottom: 4px;
}
.day-date {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.35px;
  margin-bottom: 4px;
}
.day-price {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.35px;
}
.price_list-head {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}
.price_list-column {
  display: flex;
  width: 33.3%;
}
.price_list-head .price_list-column:not(:first-child) {
  justify-content: center;
}
.price_list-head-title {
  color: #9BA4AB;
  font-style: normal;
  letter-spacing: 0.35px;
  display: flex;
  flex-direction: column;
  margin: 0;
}
.price_list-head-title:not(span) {
  font-weight: 600;
}
.price_list-head-title span {
  font-weight: 400;
}
.step-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #FFF;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.35px;
  border-radius: 16px;
  outline: 0;
  border: 0;
  font-family: 'Futura PT';
}
.step-button:disabled {
  background: #9BA4AB;
}
.step-button:not(:disabled) {
  background: #051B2E;
}
.contact_step-caption {
  color: #051B2E;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.35px;
  margin-bottom: 32px;
}
.contact_step-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact_step-title {
  color: #051B2E;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.35px;
}
.contact_step {
  display: flex;
  flex-direction: column;
}
.input_contact {
  height: 60px;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.35px;
  color: #000;
  margin: auto;
  box-sizing: border-box;
  border-radius: 16px;
}
.input_contact:not(:last-child) {
  margin-bottom: 16px;
}
.input-border {
  border: 2px solid #051B2E;
}
.price_list-value {
  color: #051B2E;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.35px;
}
.price_list-body-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.price_list-body-item {
  border-top: 1px solid #DBE3EA;
  padding: 16px 0px;
}
.price_list-body-item:last-child {
  border-bottom: 1px solid #DBE3EA;
}
.price_list-input-block {
  display: flex;
  align-items: center;
}
.price_list-input {
  width: 30px;
  border-radius: 8px;;
  color: #051B2E;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.35px;
  -moz-appearance:textfield; /* Firefox */
}
.price_list-input::placeholder {
  color: #9BA4AB;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
.price_list-input-action {
  color: #051B2E;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.35px;
  width: 30px;
  height: 30px;
  background: none;
  border: none;
  padding: 0;
}
.price_list-body-item .price_list-column {
  align-items: center;
}
.price_list-body {
  margin-bottom: 32px;
}
.price_list-footer {
  display: flex;
  align-items: center;
}
.price_list_total-block {
  display: flex;
  align-items: center;
}
.price_list_total {
  color: #051B2E;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.35px;
}
span.price_list-input {
  display: flex;
  align-items: center;
  justify-content: center;
}
.total__list_date {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.3499999940395355px;
  text-align: center;
  margin: auto;
  display: block;
  margin-bottom: 32px;
}
.contact_step-form {
  display: flex;
  flex-direction: column;
}
.sale_info-block-header-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.3499999940395355px;
  text-align: center;
  margin: 0px auto 16px auto;
}

.sale_info-block-header-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.3499999940395355px;
  text-align: center;
  margin: 0px auto 16px auto;
  color: #051B2E;
}
.sale_info-block-header-faq {
  padding-left: 0px;
  list-style-type: none;
}
.sale_info-block-header-faq li {
  padding-left: 0px;
  list-style-type: none;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.3499999940395355px;
  color: #051B2E;
  align-items: center;
  margin-bottom: 4px;
}
.sale_info-block-header-faq li span {
  padding-right: 8px;
  width: 25px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}
.loader {
  height: 40px;
  width: 40px;
}
.total_step_phone-text {
  letter-spacing: 0.35px;
  color: #9BA4AB;
  text-align: center;
}
.total_step_phone-text span:first-child {
  font-weight: 400;
}
.total_step_total {
  margin: 16px 0px 32px auto;
  text-align: right;
  font-weight: 600;
  letter-spacing: 0.35px;
}
.total_step_total span {
  display: block;
}
.total_step_total span:first-child {
  font-size: 18px;
  line-height: 28px;
  color: #9BA4AB;
  margin-bottom: 8px;
}
.total_step_total span:last-child {
  font-size: 30px;
  line-height: 28px;
  color: #051B2E;
}
.sale_info_title {
  font-size: 26px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.3499999940395355px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
}
.sale_info_date {
  font-size: 20px;
  line-height: 25.64px;
  letter-spacing: 0.3499999940395355px;
  text-align: center;    
  display: block;
  margin-bottom: 24px;
}
.sale_info_date span {
  font-weight: 600;
}
.qr_list {
  display: flex;
  overflow-x: auto;
  margin-bottom: 32px;
}
.qr_list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  float: left;
  flex: none;
  width: 100%;
}
.qr_list-item:not(:first-child) {
  margin-left: -40%;
}
.qr_list-item-name {
  font-family: Futura PT;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.3499999940395355px;
  text-align: center;
  color: #9BA4AB;
  margin-bottom: 5px;
}
.list_type_nav {
  display: flex;
}
.list_type_nav button {
  outline: 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: 3px solid #051B2E;
  border-radius: 16px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.3499999940395355px;
  box-sizing: border-box;
  font-family: 'Futura PT';
}
.list_type_nav button:not(.active) {
  background: unset;
  color: #051B2E;
}
.list_type_nav button.active {
  background: #051B2E;
  color: #fff;
}
.react-calendar__tile {
  height: 32px;
  width: 41px;
  outline: 0;
  border: oldlace;
  background: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: 0.3499999940395355px;
  text-align: center;
}
.react-calendar__month-view__weekdays__weekday {
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
  letter-spacing: 0.3499999940395355px;
  text-align: center;
  color: #7B827E;
  text-decoration: unset;
  text-transform: uppercase;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: unset;
}
.days_list_calendar-block {
  box-shadow: 0px 2px 86px 0px #005C8B8A;
  border-radius: 16px;
  padding: 16px;
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  min-width: 335px;
}
.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  letter-spacing: 0.3499999940395355px;
  text-align: center;
}
.react-calendar__navigation button {
  border: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-calendar__navigation {
  display: flex;
}
.react-calendar__navigation__arrow {
  width: 40px;
  height: 40px;
  font-size: 30px;
}
.react-calendar__month-view__weekdays {
  margin: 20px 0px;
}
.react-calendar__navigation__label {
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  letter-spacing: 0.3499999940395355px;
  text-align: center;
  color: #0A1811;
  text-transform: CAPITALIZE;
}
.days_list_calendar-closure {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.1);
  z-index: 100;
}
.price_help {
  background: #D6EEFC;
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 8px 10px 8px 10px;
  margin: 0px 8px 24px 8px;
}
.price_help svg {
  margin-right: 5px;
}
.price_help span {
  color: #112867;
  font-family: 'Futura PT';
  font-size: 14px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0.3499999940395355px;
  display: flex;
  flex: 1;
}
.price_list-info {
  font-size: 12px;
  font-weight: 450;
  line-height: 15.38px;
  letter-spacing: 0.3499999940395355px;
  text-align: center;
  color: #9BA4AB;
  margin-top: 24px;
  display: flex;
  justify-content: center;
}
.sale_info_phone {
  font-size: 20px;
  font-weight: 450;
  line-height: 25px;
  letter-spacing: 0.35px;
  text-align: center;
  color: #051B2E;
  margin: 0 auto 24px auto;
  display: block;
}
.sale_info_phone a {
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.35px;
  text-align: center;
  color: #051B2E;
}
.sale_info_email {
  font-size: 16px;
  font-weight: 450;
  line-height: 20.51px;
  letter-spacing: 0.3499999940395355px;
  text-align: center;
  color: #9BA4AB;
  margin: 0 auto 24px auto;
  display: block;
}
.sale_info_email b {
  font-family: Futura PT;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.51px;
  letter-spacing: 0.3499999940395355px;
  text-align: center;

}
.sale_info_helper {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 12px;
  justify-content: center;
}
.sale_info_helper_paid {
  background-color: #D6EEFC;
  color: #112867;
}
.sale_info_helper_created {
  background-color: #FCEDD6;
  color: #9C510B;
}
.sale_info_helper svg {
  margin-right: 10px;
}
.order_cancel-block {
  display: flex;
  flex-direction: column;
}
.order_cancel-title {
  font-size: 26px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.3499999940395355px;
  text-align: center;
}
.order_cancel-description {
  font-weight: 500;
  letter-spacing: 0.3499999940395355px;
  color: #051B2E;
  margin-bottom: 24px;
}
.order_cancel-text:not(:last-child) {
  margin-bottom: 16px;
}
.order_cancel-text:last-child {
  margin-bottom: 24px;
}
.order_cancel_btn-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.order_cancel_caption-block {
  margin: auto;
}
.order_cancel-btn {
  display: flex;
  padding: 16px 32px;
  margin: 0px 8px;
  border-radius: 16px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.order_cancel_btn-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.order_cancel-btn_yes {
  background: #B91313;
}
.order_cancel-btn_no {
  background: #188B1D;
}
.order_cancel-btn_back {
  background: #051B2E;
}
.order_cancel_action {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.3499999940395355px;
  color: #B91313;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  cursor: pointer;
}
.sale_info_faq-list {
  list-style-type: none;
  padding-left: 0;
}
.sale_info_faq-list li span {
  margin-right: 5px;
}
.sale_info_faq-list li {
  margin-bottom: 10px;
  color: #051B2E;
}
@media (prefers-reduced-motion: no-preference) {
  .loader {
    animation: Loader-sping infinite 2.5s linear;
  }
}
@keyframes Loader-sping {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 992px) {
  .app-wrapper {
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    padding-bottom: 100px;
  }
  .screen-wrapper {
    width: 100%;
    min-height: 100%;
  }
  .buy_nav {
    margin-bottom: 16px;
    padding: 8px;
  }
  .buy_nav span {
    font-size: 20px;
  }
  .days_list-item {
    flex: none;
  }
  .days_list-item:not(:first-child), .days_list-item:not(:last-child) {
    margin: 0px 4px;
  }
  .days_list-item:first-child {
    margin-left: 8px;
    margin-right: 4px;
  }
  .days_list-item:last-child {
    margin-left: 4px;
    margin-right: 8px;
  }
  .days_list {
    overflow-x: auto;
  }
  .price_list-head {
    padding: 0px 8px;
  }
  .price_list-head-title:not(span) {
    font-size: 16px;
  }
  .price_list-head-title span {
    font-size: 13px;
  }
  .price_list-value {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .price_list-body-row .price_list-column:not(:first-child) {
    flex-direction: column;
    justify-content: center;
  }
  .price_list {
    padding: 0px 8px;
  }
  .price_list-input {
    height: 30px;
    font-size: 16px;
  }
  .price_list_total_type-block {
    display: none;
  }
  .price_list_total {
    font-size: 30px;
  }
  .price_list .step-button {
    width: 50%;
    height: 60px;
  }
  .price_list_total-block {
    width: 50%;
    justify-content: center;
  }
  .contact_step-title {
    font-size: 20px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .contact_step-caption {
    font-size: 20px;
  }
  .contact_step-footer .step-button:first-child {
    padding-left: 32px;
    padding-right: 32px;
    margin-left: 8px;
    margin-right: 4px;
  }
  .contact_step-footer .step-button {
    padding-bottom: 16px;
    padding-top: 16px;
  }
  .contact_step-footer .step-button:last-child {
    width: 100%;
    margin-right: 8px;
    margin-left: 4px;
  }
  .input_contact {
    width: calc(100% - 16px);
    margin: 0px 8px;
    padding: 16px 32px;
  }
  .total_step_phone-text {
    line-height: 20px;
    margin-bottom: 16px;
    font-size: 16px;
  }
  .list_type_nav {
    margin: 8px 8px 16px 8px;
  }
  .list_type_nav button:not(:first-child) {
    margin-left: 4px;
  }
  .list_type_nav button:not(:last-child) {
    margin-right: 4px;
  }
  .days_list_calendar-block {
    margin: 140px 5px 0px 5px;
  }
  .order_cancel-title {
    margin-bottom: 16px;
  }
  .order_cancel-text {
    font-size: 20px;
    line-height: 25.64px;
  }
  .order_cancel-text {
    padding: 0px 20px;
  }
  .order_cancel-block {
    margin: 8px;
  }
}
@media (min-width: 992px) {
  .app-wrapper {
    width: 100vw;
    height: 100vh;
  }
  .screen-wrapper {
    margin-top: 120px;
    width: 670px;
    height: fit-content;
    box-shadow: 0px 2px 86px 0px rgba(0, 92, 139, 0.54);
    border-radius: 32px;
    padding: 32px;
  }
  .buy_nav {
    margin-bottom: 32px;
  }
  .buy_nav span {
    font-size: 30px;
  }
  .days_list-item:not(:last-child) {
      margin-right: auto;
  }
  .price_list-head-title:not(span) {
    font-size: 18px;
  }
  .price_list-head-title span {
    font-size: 14px;
  }
  .price_list-value {
    font-size: 30px;
    margin-right: 10px;
  }
  .price_list-body-row .price_list-column:not(:first-child) {
    justify-content: right;
  }
  .price_list-input {
    height: 40px;
    font-size: 20px;
  }
  .price_list_total_type-block {
    margin-right: 16px;
    display: flex;
    flex-direction: column;
  }
  .price_list_total_type-block > span {
    color: #9BA4AB;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    letter-spacing: 0.35px;
  }
  .price_list_total {
    font-size: 45px;
  }
  .contact_step-footer .step-button:first-child {
    padding: 16px 32px;
  }
  .price_list .step-button, .contact_step-footer .step-button[type=submit] {
    padding: 16px 64px;
  }
  .price_list-footer button {
    margin-left: auto;
  }
  .price_list_total-block {
    margin-right: auto;
  }
  .contact_step-caption {
    font-size: 30px;
  }
  .contact_step-footer .step-button {
    margin: 0px 8px;
  }
  .contact_step-title {
    font-size: 30px;
  }
  .input_contact {
    width: 335px;
    padding: 16px 24px;
  }
  a.step-button {
    padding: 16px 64px;
  }
  .total_step_phone-text {
    line-height: 23.08px;
    margin-bottom: 32px;
    font-size: 18px;
  }
  .list_type_nav {
    margin-bottom: 32px;
  }
  .list_type_nav button:not(:first-child) {
    margin-left: 16px;
  }
  .list_type_nav button:not(:last-child) {
    margin-right: 16px;
  }
  .days_list-item {
    position: relative;
  }
  .order_cancel-title {
    margin-bottom: 24px;
  }
  .order_cancel-text {
    font-size: 20px;
    line-height: 32.05px;
  }
}